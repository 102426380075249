<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="information" service="refCredit" firstStep backlink="/refinance-credit/registration" />
      <div class="content">
        <div class="content__container mobile_center">
          <p class="promo promo_blocks2">{{ "ref_members_text" | localize }}</p>
          <div class="service__container">
            <button class="service service service_members service_members_1" @click="saveServiceInfo('refCredit', { name: 'Refinance Credit clients', params: { clients: 1 } }, { credit_type: 0 })">
              <div class="service__title">{{ ("s2p5_block_1_title") | localize }}</div>
            </button>
            <button class="service service service_members service_members_2" @click="saveServiceInfo('refCredit', { name: 'Refinance Credit clients', params: { clients: 2 } }, { credit_type: 0 })">
              <div class="service__title">{{ ("s2p5_block_2_title") | localize }}</div>
            </button>
            <v-tooltip bottom class="tooltip_wide">
              <template v-slot:activator="{ on, attrs }">
                <button v-bind="attrs" v-on="on" class="service service_credit service_credit_text service_credit_1" @click="saveServiceInfo('refCredit', { name: 'Refinance Credit clients', params: { clients: 2 } }, { credit_type: 1 })">
                  <div class="service__title">{{ "ref_credit_deposit" | localize }}</div>
                </button>
              </template>
              <TextBlock :withTitle="false" :id="18" />
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import TextBlock from '@/components/Text.vue'
import { saveMixin } from '@/mixins/saveMixin.js'

export default {
  name: 'Ref_Credit_members',
  components: { Backlink, TextBlock },
  mixins: [saveMixin]
}
</script>
